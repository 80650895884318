import React from 'react';

const PhotoGallery = () => {
  return (
    <div>
      <h1>Photo Gallery</h1>
      <p>Check out our amazing bee photos!</p>
      {/* Add photo gallery content here */}
    </div>
  );
}

export default PhotoGallery;